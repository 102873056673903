var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "tCertificateManage",
      attrs: { title: _vm.editTitle, "mask-closable": false },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "Form",
        {
          ref: "tCertificateManageForm",
          attrs: {
            model: _vm.tCertificateManageForm,
            rules: _vm.tCertificateManageFormRule,
            "label-width": 100,
          },
        },
        [
          _c(
            "FormItem",
            { attrs: { label: "编号", prop: "code" } },
            [
              _c("Input", {
                attrs: {
                  disabled: _vm.disabled,
                  type: "text",
                  maxlength: 50,
                  placeholder: "请输入编号",
                },
                model: {
                  value: _vm.tCertificateManageForm.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.tCertificateManageForm, "code", $$v)
                  },
                  expression: "tCertificateManageForm.code",
                },
              }),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "姓名", prop: "name" } },
            [
              _c("Input", {
                attrs: {
                  disabled: _vm.disabled,
                  type: "text",
                  maxlength: 50,
                  placeholder: "请输入姓名",
                },
                model: {
                  value: _vm.tCertificateManageForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.tCertificateManageForm, "name", $$v)
                  },
                  expression: "tCertificateManageForm.name",
                },
              }),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "性别", prop: "sex" } },
            [
              _c("Input", {
                attrs: {
                  disabled: _vm.disabled,
                  type: "text",
                  maxlength: 50,
                  placeholder: "请输入性别",
                },
                model: {
                  value: _vm.tCertificateManageForm.sex,
                  callback: function ($$v) {
                    _vm.$set(_vm.tCertificateManageForm, "sex", $$v)
                  },
                  expression: "tCertificateManageForm.sex",
                },
              }),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "年龄", prop: "age" } },
            [
              _c("Input", {
                attrs: {
                  disabled: _vm.disabled,
                  type: "text",
                  maxlength: 50,
                  placeholder: "请输入年龄",
                },
                model: {
                  value: _vm.tCertificateManageForm.age,
                  callback: function ($$v) {
                    _vm.$set(_vm.tCertificateManageForm, "age", $$v)
                  },
                  expression: "tCertificateManageForm.age",
                },
              }),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "有效期", prop: "term_of_validity" } },
            [
              _c("Input", {
                attrs: {
                  disabled: _vm.disabled,
                  type: "text",
                  maxlength: 50,
                  placeholder: "请输入有效期",
                },
                model: {
                  value: _vm.tCertificateManageForm.termOfValidity,
                  callback: function ($$v) {
                    _vm.$set(_vm.tCertificateManageForm, "termOfValidity", $$v)
                  },
                  expression: "tCertificateManageForm.termOfValidity",
                },
              }),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "发证单位", prop: "unit_of_issue" } },
            [
              _c("Input", {
                attrs: {
                  disabled: _vm.disabled,
                  type: "text",
                  maxlength: 50,
                  placeholder: "请输入发证单位",
                },
                model: {
                  value: _vm.tCertificateManageForm.unitOfIssue,
                  callback: function ($$v) {
                    _vm.$set(_vm.tCertificateManageForm, "unitOfIssue", $$v)
                  },
                  expression: "tCertificateManageForm.unitOfIssue",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: { type: "text" },
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          !_vm.disabled
            ? _c(
                "Button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.handSubmit },
                },
                [_vm._v("提交")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }